<script>
import { required } from "vuelidate/lib/validators";
import { authService } from "../../../helpers/auth.service";

export default {
  data() {
    return {
      userphone: "",
      password: "",
      submitted: false,
      minutes: 60,
      countdown: null,
      showDismissibleAlert: false,
      TextAlert: "",
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    userphone: { required },
    password: { required },
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { userphone, password } = this;
        if (userphone && password) {
          authService.login(userphone, password).then(
            () => {
              location.href = "/";
            },
            (error) => {
              this.showDismissibleAlert = true;
              this.TextAlert = error.message;
              if (error.code == 410) {
                this.startTimer();
              }
            }
          );
        }
      }
    },
    startTimer() {
      this.minutes = 60;
      let seconds = this.minutes;
      let et = this.$refs.elaspedTime;
      this.$refs.elaspedTimeP.classList.remove("d-none");
      et.style.width = "0%";
      this.countdown = setInterval(() => {
        this.minutes--;
        et.style.width = ((seconds - this.minutes) * 100) / seconds + "%";
        if (this.minutes === 0) {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      this.$refs.elaspedTimeP.classList.add("d-none");
      clearInterval(this.countdown);
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo-light.png"
                              height="100"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 m-4">خوش آمدید !</h4>
                        <p class="text-muted">
                          پنل دستیار شناسایی زیان پنهان گاوداری
                        </p>
                        <p class="text-muted">برای ادامه به وارد شوید .</p>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                        >{{ notification.message }}</b-alert
                      >

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="showDismissibleAlert"
                          variant="danger"
                          dismissible
                          >{{ TextAlert }}</b-alert
                        >
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="userphone">{{
                              $t("menuitems.authentication.list.userphone")
                            }}</label>
                            <input
                              type="text"
                              v-model="userphone"
                              class="form-control"
                              id="userphone"
                              placeholder="وارد کنید"
                              :class="{
                                'is-invalid': submitted && $v.userphone.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.userphone.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.userphone.required"
                                >این فیلد الزامی است</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">{{
                              $t("menuitems.authentication.list.password")
                            }}</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="وارد کنید"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              رمز عبور الزامی است.
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              {{ $t("menuitems.authentication.list.login") }}
                            </button>
                          </div>
                        </form>
                      </div>
                      <div
                        class="progress progress-lg d-none"
                        ref="elaspedTimeP"
                      >
                        <div
                          class="progress-bar"
                          ref="elaspedTime"
                          role="progressbar"
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="60"
                        >
                          {{ minutes }} ثانیه
                        </div>
                      </div>
                      <div class="mt-5 text-center">
                        <p>
                          <a
                            href="https://www.zidev.site"
                            target="_blank"
                            rel="noopener noreferrer"
                            >zidev</a
                          >
                          . Crafted with
                          <i class="mdi mdi-heart text-danger"></i> by
                          <a
                            href="https://meyti-dev.ir"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Meyti-dev</a
                          >
                          ©{{ new Date().getFullYear() }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.progress-lg {
  height: 1.5rem !important;
}
</style>